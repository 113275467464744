import React from "react";

const Footer = () => {
    return(
        <>
            <footer className="w-100 bg-light text-center ">
            <p> 2021 React Website. All Rights Reserved | Terms and Conditions</p>

            </footer>
        </>
    )
};

export default Footer;
